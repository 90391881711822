<template>
	<div id="app">

		<comp-navigation />

		<transition
			name="fade-element-out"
			mode="out-in"
			appear
		>
			<router-view
				id="router-element"
			/>
		</transition>

	</div>
</template>

<script>
	export default {
		name: 'App',

		metaInfo: {
			// titleTemplate: 'Matos %s',
			// title will be injected into parent titleTemplate

			htmlAttrs: {
				lang: 'de',
			},

			link: [
				{
					rel: 'canonical',
					href: 'https://www.eureka.co.at'
				}
			]
		},

		mounted() {
			// Fixing button active style on touch
			document.addEventListener("touchstart", function() {},false);
		}
	}
</script>

<style lang="scss">
	@import './assets/css/reset.css';
	@import './assets/css/animations.css';
	@import './assets/css/layouts.scss';
	@import './assets/css/modifiers.scss';
	@import './assets/css/fonts.css';
	@import './assets/css/typography.scss';
	@import './assets/css/utilitys.scss';
	@import './assets/css/variables.css';
	@import './assets/css/scss-variables.scss';
	@import './assets/css/bubbles.scss';

	#router-element {
		padding: var(--padding-outside);
		margin-inline: auto;
	}
</style>

