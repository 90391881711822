<template>
	<div
		v-if="!imageData.textSrc"
		class="image-and-video-holder"
		:class="{
			'style-square-corners': imageData.squareCorners,
			'style-browser-bar' : imageData.isWebsite,
			'style-browser' : imageData.isWebsite,
			'style-mobile-browser' : imageData.isMobileWebsite,
		}"
		:style="`aspect-ratio: ${imageData.imgSrc.width} / ${imageData.imgSrc.height}`"
	>

		<transition name="fade-image">

			<img 
				v-if="imageData.imgSrc && !imageData.videoSrc"
				v-show="isVisible"
				:key="imageData.imgSrc.src"
				:alt="imageData.projectName + ', ' + imageData.task + '. ' + imageData.elementDescription + '.'"
				sizes="calc(250px + 10vw)"
				class="grid-image"
				:width="imageData.imgSrc.width"
				:height="imageData.imgSrc.height"
			>

			<video 
				v-else-if="imageData.videoSrc && imageData.imgSrc"
				v-show="isVisible"
				:poster="imageData.imgSrc.src"
				:width="imageData.imgSrc.width"
				:height="imageData.imgSrc.height"
				class="grid-image"
				autoplay
				loop="loop"
				muted="muted"
				webkit-playsinline="true"
				playsinline="true"
				type="video/mp4"
				:title="imageData.projectName + ', ' + imageData.task + '. ' + imageData.elementDescription + '.'"
			></video>

			<span v-if="imageData.textSrc" v-html="imageData.textSrc"></span>

		</transition>
	</div>

	<div
		v-else-if="imageData.textSrc"
		class="bubble bubble-gray"
	>

		<transition name="fade-image">

			<span v-html="imageData.textSrc"></span>

		</transition>
	</div>
</template>

<script>

	export default {
		props: [
			'imageData',
		],

		data() {
			return {
				isVisible: false,
				observer: null,
				intersected: false,
			}
		},

		methods: {
			loadImageToBody() {
				if (this.intersected === true) {

					const imageToLoad = this.$el.querySelector('img');

					imageToLoad.onload = () => {
						this.isVisible = true;
					}

					imageToLoad.srcset = this.imageData.imgSrc.srcSet; // loading the hi-res image
					imageToLoad.src = this.imageData.imgSrc.src; // loading the hi-res image
				}
			},
			loadVideoToBody() {
				if (this.intersected === true) {
					const videoToLoad = this.$el.querySelector('video')

					videoToLoad.onloadeddata = () => {
						this.isVisible = true;

						setTimeout(() => {
							this.$nextTick(() => {
								videoToLoad.play();
							})
						}, 100);
					}

					videoToLoad.src = this.imageData.videoSrc; // loading the hi-res image
				}
			}
		},

		mounted() {
			if (this.imageData.textSrc) {
				// this.$el.classList.add('text-grid', 'script-small');
				this.$el.classList.add('text-grid');
				return;
			}

			const observerConfig = {
				rootMargin: (document.documentElement.clientHeight/2) + 'px',
				threshold: 0.1
			};

			// The observer for the images on the page
			this.observer = new IntersectionObserver(entries => {

				let observerImageHolder = entries[0];

				// Are we in viewport?
				if (observerImageHolder.intersectionRatio > 0 && this.intersected === false) {

					if (this.imageData.videoSrc) {
						this.intersected = true;
						this.loadVideoToBody();
					} else if (this.imageData.imgSrc) {
						this.intersected = true;
						this.loadImageToBody();
					}

					// Stop watching and load the image
					this.observer.disconnect();
				}

			}, observerConfig);

			// Call the oberver
			this.observer.observe(this.$el);

		},
		destroyed() {
			if (this.imageData.textSrc) {
				return;
			}

			this.observer.disconnect(this.$el);
		}
	}

</script>

<style lang="scss">
	@import '../assets/css/variables.css';

	.grid-image {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		opacity: 1;

		/*Make Element unklickable for UpsMessage to appear*/
		pointer-events: none;
	}

	.fade-image-enter-active,
	.fade-image-leave-active {
		transition: opacity .5s ease;
	}

	.fade-image-enter,
	.fade-image-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.style-browser-bar {
		border-top: var(--browser-bar-height) solid var(--gray-medium);
	}

	.style-square-corners {
		/* border-radius: 0; */
	}

	.grid-image>video {
		width: 100%;
	}

	.style-mobile-browser {
		box-shadow: var(--box-shadow-phone);
		// border-radius: var(--border-radius);
		position: relative
	}

	.style-browser {
		box-shadow: var(--box-shadow-small);

		&::before {
			content: "";
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(240,240,240, .25);
			z-index: 0;
			pointer-events: none;

			@supports (mix-blend-mode: multiply) {
				mix-blend-mode: multiply;
			}
		}
	}


</style>
