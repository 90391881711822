import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    phone: {
      number: '+4367761001575',
      layout: '+43 677 6100 15 75'
    },
    digital_meetingroom: {
      link: '+4367761001575',
      layout: 'zoom.com/eureka'
    },
    mail: 'mail@eureka.co.at',
    mailInquire: 'anfrage@eureka.co.at',
    mailJobs: 'jobs@eureka.co.at',
    domain: {
      full: 'https://www.eureka.co.at',
      written: 'www.eureka.co.at'
    },
  },
  mutations: {

  },
  actions: {

  }
})
