import Vue from 'vue'
import Router from 'vue-router'
import VueAnalytics from 'vue-analytics'

import CompImage from './components/CompImage'

Vue.use(Router)

Vue.component('comp-navigation', () => import(/* webpackChunkName: "CompNavigation" */ './components/CompNavigation'))
Vue.component('comp-image', CompImage)
Vue.component('comp-ups-message', () => import(/* webpackChunkName: "CompUpsMessage" */ './components/CompUpsMessage'))

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
  {
    path: '/aab',
    name: 'AAB',
    component: () => import(/* webpackChunkName: "PageAab" */ './views/PageAab.vue'),
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "PageContact" */ './views/PageContact.vue'),
  },
  {
    path: '/',
    hash: '#hero',
    name: 'Home',
    component: () => import(/* webpackChunkName: "PageHome" */ './views/PageHome.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "PageImprint" */ './views/PageImprint.vue'),
  },
  {
    path: '/leistungen',
    name: 'Leistungen',
    component: () => import(/* webpackChunkName: "PageServices" */ './views/PageServices.vue'),
  },
  {
    path: '*',
    name: '404',
    component: null,
  }
  ],
  scrollBehavior (to, from, savedPosition) {
    // vue-router does not incorporate scroll-margin-top on its own.
    const hashString = to.hash.replace(`#`, ``)
    const elementToBeScrolledTo = document.querySelector(`[id='${hashString}']`)

    if (elementToBeScrolledTo) {
      const offset = parseFloat(getComputedStyle(elementToBeScrolledTo).scrollMarginTop)

      return {
        selector: to.hash,
        offset: { y: offset },
        behavior: 'smooth'
      }
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 1000)
    })
  }
})

export default router;